
import { mapGetters, mapActions } from "vuex"
import cloneDeep from "lodash/cloneDeep"
import ColorPickerPeppermint from "@/dashboard/forms/color-picker-peppermint"

/**
 * Modal for displaying the Website colors
 */
export default {
  name: "WebsiteColorPalette",
  components: {
    ColorPickerPeppermint
  },
  data() {
    return {
      website: {
        global_styles: {
          color_main: "#ffffff",
          color_alt: "#ffffff",
          color_background: "#ffffff",
          color_text: "#ffffff",
          color_heading: "#ffffff",
          color_link: "#ffffff",
          color_alert: "#ffffff",
          color_success: "#ffffff",
          color_svg_dark_side: "#ffffff",
          color_svg_light_side: "#ffffff",
          color_svg_color_fill: "#ffffff",
          color_svg_white_fill: "#ffffff"
        }
      },
      color_text: "#33475b"
    }
  },
  computed: {
    ...mapGetters({
      websiteOptions: "website/getSelectedWebsite"
    })
  },
  watch: {
    websiteOptions: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.website = cloneDeep(this.websiteOptions)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.websiteOptions) {
      this.website = cloneDeep(this.websiteOptions)
    } else {
      this.setWebsiteOptions(null)
    }
  },
  methods: {
    ...mapActions({
      setWebsiteOptions: "website/setWebsiteOptions"
    }),
    async onCopy(value, color = "") {
      await navigator.clipboard.writeText(value)
      this.toastSuccess(`Copied to ${color} clipboard`)
    }
  }
}
