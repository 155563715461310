
import FeatherIcon from "@/helpers/FeatherIcon"
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'

export default {
  name: 'NavbarBananaPageHelpLink',
  components: {
    FeatherIcon,
    videoPlayer
  },
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      playerOptions: {
        // videojs options
        autoplay: false,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: []
      },
    }
  },
  methods: {
    startTour(link) {
      this.sendHotjarEvent({ action: 'Tour Started Click', path: window.location.pathname, ...link })
      if (window && window.Intercom && link.id) {
        window.Intercom('startTour', link.id)
      }
    },
    playVideo(link) {
      // console.log("🚀 ~ file: NavbarBananaPageHelpLink.vue ~ line 55 ~ playVideo ~ link", link)
      this.sendHotjarEvent({ action: 'Video Play Click', path: window.location.pathname, ...link })

    },
    clickHelpArtcle(link) {
      // console.log("🚀 ~ file: NavbarBananaPageHelpLink.vue ~ line 86 ~ clickHelpArtcle ~ link", link)
      this.sendHotjarEvent({ action: 'Help Article Click', path: window.location.pathname, ...link })
    }
  }
}
