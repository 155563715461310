

/**
  TODO use https://vuetifyjs.com/en/components/skeleton-loaders instead
*/
export default {
  name: 'LoadingSpinner',
  props: {
    height: {
      type: Number,
      default: 40
    }
  }
}
