import { render, staticRenderFns } from "./WebsiteColorPalette.vue?vue&type=template&id=fdd64a5e&scoped=true&lang=pug"
import script from "./WebsiteColorPalette.vue?vue&type=script&lang=js"
export * from "./WebsiteColorPalette.vue?vue&type=script&lang=js"
import style0 from "./WebsiteColorPalette.vue?vue&type=style&index=0&id=fdd64a5e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdd64a5e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ColorPickerPeppermint: require('/dashboard/forms/color-picker-peppermint/ColorPickerPeppermint.vue').default})
