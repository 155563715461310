
import { mapGetters } from "vuex"
import FeatherIcon from "@/helpers/FeatherIcon"

export default {
  name: 'NavbarBananaDropdown',
  components: {
    FeatherIcon,
  },
  props: {
    totalUnreadNotifications: {
      type: Number,
      default: 0
    },
    /**
     * Menu Items for dropdown menu.
     *
     * { MenuItem }
     * - type: String of menu item type (undefined | divider)
     * - icon: String of icon for menu item
     * - class: String custom classes for the menu item
     * - link: String router link for menu item
     * - href: String href link for menu item
     * - text: String content of menu item
     */
    menu: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters({
      selectedAccount: "accounts/getSelectedAccount",
      selectedWebsite: "website/getSelectedWebsite",
      isTestMode: "activityPopup/getIsTestMode",
      user: "user/getUser"
    }),
  },
  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen
    },
  }
}
