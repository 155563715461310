import { render, staticRenderFns } from "./NavbarBananaDropdown.vue?vue&type=template&id=35632dbb&lang=pug"
import script from "./NavbarBananaDropdown.vue?vue&type=script&lang=js"
export * from "./NavbarBananaDropdown.vue?vue&type=script&lang=js"
import style0 from "./NavbarBananaDropdown.vue?vue&type=style&index=0&id=35632dbb&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeatherIcon: require('/helpers/FeatherIcon.vue').default,BaseNavDropdown: require('/dashboard/navigation/base-nav-dropdown/BaseNavDropdown.vue').default})
