
import { mapGetters, mapMutations, mapActions } from "vuex"
import FeatherIcon from "@/helpers/FeatherIcon"
import _ from "lodash"
import SandwichSearchFuse from "@/dashboard/search/sandwich-search-fuse"
import { generateApiUrl } from "@/utils/helpers"

export default {
  name: 'NavbarBananaAccountsDropdown',
  components: {
    FeatherIcon,
    SandwichSearchFuse
  },
  data() {
    return {
      userAccounts: null,
      initialAccounts: null,
      apiUrl: null,
      isDropdown: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      axios.defaults.headers.common['Authorization'] = this.currentAuthToken
      // console.log("🚀 ~ file: NavbarBananaAccountsDropdown.vue ~ line 78 ~ this.$nextTick ~ this.currentAuthToken", this.currentAuthToken)
      this.loadAccounts()
    })
  },
  computed: {
    ...mapGetters({
      selectedAccount: "accounts/getSelectedAccount",
      selectedWebsite: "website/getSelectedWebsite",
      currentAuthToken: "user/getCurrentAuthToken",
      isTestMode: "activityPopup/getIsTestMode",
      user: "user/getUser",
      accountSwitcherAccounts: "user/getAccountSwitcherAccounts",
    }),
    isAccountsPage() {
      return this.$route.name === 'accounts'
    },
    showAccountDropdown() {
      if (this.user && (this.user.is_super_admin || this.user.is_website_builder)) {
        return true
      }
      else if (this.user && this.user.accounts && this.user.accounts.length > 1) {
        return true
      }
      else {
        return false
      }
    },
  },
  methods: {
    ...mapMutations({
      updateLayout: "layoutVars/updateLayout",
      updateAccountTags: "layoutVars/updateAccountTags",
      updateShowTags: "layoutVars/updateShowTags",
      updateAccountTagsRef: "layoutVars/updateAccountTagsRef",
      updateAccountTagsIndex: "layoutVars/updateAccountTagsIndex",
      updateSearchItems: "layoutVars/updateSearchItems",
      setIsResetAccount: "layoutVars/setIsResetAccount",
      resetAccounts: "accounts/resetAccounts",
      resetWebsite: "website/resetWebsite",
      resetMedia: "media/resetMedia",
      resetPages: "page/resetPages",
      resetEditorSidebar: "editorSidebar/resetEditorSidebar",
    }),
    ...mapActions({
      createAccount: "accounts/createAccount",
      setSelectedAccountId: "user/setSelectedAccountId",
      setDataHasLoaded: "appState/setDataHasLoaded",
      setAccountSwitcherAccounts: "user/setAccountSwitcherAccounts",
    }),
    async onAccountClick(account) {
      // console.log("🚀 ~ file: NavbarBananaAccountsDropdown.vue ~ line 69 ~ onAccountClick ~ account", account)
      // this.setDataHasLoaded(false)
      this.resetAccounts()
      this.setSelectedAccountId(null)
      this.resetWebsite()
      this.resetMedia()
      this.resetEditorSidebar()
      this.resetPages()
      this.setIsResetAccount(true)
      this.$store.commit("locations/CLEAR_LOCATIONS_ADDRESS")
      this.$store.commit("leads/updateFilterLocations")
      // await this.$vlf.setItem('leads/filterLocations', null)
      if (this.user.is_super_admin || this.user.is_website_builder) {
        // Redirect admin to website settings
        this.$router.replace(`/website-manager/${ account.id }/options`)
      }
      else {
        this.$router.replace(`/home/${ account.id }`)
      }
      this.resetAccount(account.id)
    },
    async resetAccount(account_id) {
      await this.$store.dispatch("user/setSelectedAccountId", account_id)
      await this.$store.dispatch("accounts/setSelectedAccount", account_id, { root: true })
      if (window && window.Intercom) {
        window.Intercom('update', { "last_selected_account_id": account_id })
      }
      // Update store with selected account
      const res = await this.$store.dispatch("accounts/setAccounts", {
        account_id: account_id,
        relations: "address,review_settings,websites,main_image,demo_unit_variables,global_settings,global_settings.schema",
      })

      const accountId = this.$store.getters["user/getSelectedAccountId"]
      const user = this.$store.getters["user/getUser"]
      if (accountId && user) this.$store.dispatch("navigation/buildSidebarMenu", {})

      // Update data has loaded flag
      this.$store.dispatch("appState/setDataHasLoaded", true)
      this.setIsResetAccount(false)
    },
    createApiUrl() {
      const endpoint = "account/reference"
      const url = generateApiUrl(`${ endpoint }`)
      this.apiUrl = url
    },
    async loadAccounts() {
      try {
        if (this.accountSwitcherAccounts == null) {
          this.createApiUrl()
          let { data: res } = await axios.post(this.apiUrl, { selectedTags: this.selectedTags }, { headers: { "User-ID": this.user.id, filterByOnboarding: this.filterByOnboarding ? "yes" : "no", "x-purge": true } })
          let accounts = res.data

          this.setAccountSwitcherAccounts(accounts)
          this.initialAccounts = _.cloneDeep(accounts)
          this.userAccounts = _.cloneDeep(accounts)
        }
        else {
          this.initialAccounts = _.cloneDeep(this.accountSwitcherAccounts)
          this.userAccounts = _.cloneDeep(this.accountSwitcherAccounts)
        }

        // this.updateSearchItems(this.accounts)
      } catch (e) { }
      this.isLoading = false
    },
    onInput(e) {
      if (e == null) {
        this.userAccounts = _.cloneDeep(this.initialAccounts)
      }
      else {

        this.userAccounts = e
      }
    }
  }
}
