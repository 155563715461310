
export default {
  name: 'BaseNavDropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown html tag (e.g div, ul etc)'
    },
    titleTag: {
      type: String,
      default: 'button',
      description: 'Dropdown title (toggle) html tag'
    },
    title: {
      type: String,
      default: null,
      description: 'Dropdown title'
    },
    direction: {
      type: String,
      default: 'down', // up | down
      description: 'Dropdown menu direction (up|down)'
    },
    icon: {
      type: String,
      default: null,
      description: 'Dropdown icon'
    },
    titleClasses: {
      type: [String, Object, Array],
      default: null,
      description: 'Title css classes'
    },
    menuClasses: {
      type: [String, Object],
      default: null,
      description: 'Menu css classes'
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether menu should appear on the right'
    },
    hasToggle: {
      type: Boolean,
      description: 'Whether dropdown has arrow icon shown',
      default: true
    },
    toggleOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  watch: {
    toggleOpen() {
      this.toggleDropDown()
    }
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen
      this.$emit('change', this.isOpen)
      this.$emit('onOpen')
    },
    closeDropDown() {
      this.isOpen = false
      this.$emit('change', false)
    }
  }
}
