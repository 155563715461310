
import { mapGetters } from "vuex"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const SitelinkAPI = ApiFactory.get("sitelink")
const ImporterApi = ApiFactory.get("importer")

export default {
  name: "CopyClientModal",
  data() {
    return {
      isAdvanced: false,
      /**
       * Please do not change these values, unless you change the same values in the
       * import-client controller on `pizza-api`.
       */
      checklistSelected: ["account", "locations", "websites", "email_notifications", "media", "nav_menus"],
      checklistOptions: [
        { text: "Account", value: "account" },
        { text: "Locations", value: "locations" },
        { text: "Websites", value: "websites" },
        { text: "Email Notifications", value: "email_notifications" },
        { text: "Media", value: "media" },
        { text: "Nav Menus", value: "nav_menus" },
      ],
      isLoading: false,
      importErrors: null,
      previousTimeout: null,
      toEnvironment: null,
      toOptions: [
        { value: null, text: "Please select an environment" },
        { value: "staging", text: "Copy to staging" },
        { value: "prod", text: "Copy to production" },
      ],
    }
  },
  computed: {
    ...mapGetters({
      selectedAccount: "accounts/getSelectedAccount",
    }),
  },
  methods: {
    eventClicked(e) {
      if (e === "update_cache") {
        clearTimeout(this.previousTimeout)
        this.previousTimeout = setTimeout(() => {
          this.updateCache()
        }, 200)
      } else if (e === "copy_client_website") {
        this.$bvModal.show("importer-modal")
      }
    },
    /**
     * Import client based on modal settings
     *
     * @todo
     * - Add options to select portions of account we want to import
     */
    async onImportClient(event) {
      this.isLoading = true
      try {
        // Create checklist object
        const checklist = {}
        for (let item of this.checklistOptions) {
          const index = this.checklistSelected.findIndex((i) => i == item.value)
          if (index > -1) {
            checklist[item.value] = true
          } else {
            checklist[item.value] = false
          }
        }

        // Import to specific environment
        if (this.toEnvironment === "stage" || this.toEnvironment === "staging") {
          const staging_endpoint = `https://api.storpug.com/${ this.$config.api_version }`
          const { data: response } = await ImporterApi.importClient(this.selectedAccount, staging_endpoint, checklist)
        } else if (this.toEnvironment === "prod" || this.toEnvironment === "production") {
          const prod_endpoint = `https://ch14516-prod.pugselfstorage.com/${ this.$config.api_version }`
          const { data: response } = await ImporterApi.importClient(this.selectedAccount, prod_endpoint, checklist)
        }

        this.importErrors = null

        this.isLoading = false
        // Close modal
        this.$bvModal.hide("importer-modal")
      } catch (error) {
        this.isLoading = false
        this.importErrors = error
        this.toastError("Error importing client.")
      }
    },
    /**
     * Reset importer form models
     */
    onImporterModalHidden() {
      this.isAdvanced = false
      this.checklistSelected = ["account", "locations", "websites", "email_notifications", "media", "nav_menus"]
      this.importErrors = null
      this.toEnvironment = null
    },
  },
}
