import * as _ from 'lodash'
import * as uuid from 'uuid/v1'
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { BFormSelect, BFormGroup } from 'bootstrap-vue'

import CollapsibleCardLasagne from '@/dashboard/cards/collapsible-card-lasagne'
import ColorPickerPeppermint from '@/dashboard/forms/color-picker-peppermint'
import BrowseImagesMozzarella from '@/dashboard/forms/browse-images-mozzarella'
import ImageFilterDropdown from '@/dashboard/forms/image-filter-dropdown'
import CollapsibleFormGroup from '@/dashboard/website-editor/collapsible-form-group'

const STYLE_OPTION_FIELDS = [
  // We need id to be able to update the style option, this gets hidden in the front-end so it's not editable
  'id',
  'class_list',
  'inline_styles'
  // 'wrapping_class_list',
  // 'wrapping_styles'
  // 'wrapping_html',
]

const ROW_STYLE_OPTION_FIELDS = ['wrapping_class_list', 'wrapping_styles']

const METADATA_FIELDS = ['title', 'name', 'description', 'route']

export default {
  components: {
    BFormGroup,
    BFormSelect,
    CollapsibleCardLasagne,
    ColorPickerPeppermint,
    BrowseImagesMozzarella,
    ImageFilterDropdown,
    ValidationObserver,
    CollapsibleFormGroup
  },
  data() {
    return {
      // Dynamic form stuff
      isLoading: false,
      initialData: null,
      form: {},
      localForm: null,
      cachedForm: null,
      localSettings: null,
      cachedSettings: null,
      availableComponents: [],
      isSaved: false
    }
  },
  computed: {
    ...mapGetters({
      showEditorSidebar: 'editorSidebar/getShowEditorSidebar',
      toggleEditorSidebar: 'editorSidebar/getToggleEditorSidebar'
    }),
    /**
     * Check if we are editing a navbar or footer component
     */
    isNavigation() {
      if (this.formSettings && this.formSettings.isNavigation) {
        return this.formSettings.isNavigation
      } else {
        return false
      }
    },
    isNavbar() {
      if (this.formSettings && this.formSettings.isNavbar) {
        return this.formSettings.isNavbar
      } else {
        return false
      }
    },
    isFooter() {
      if (this.formSettings && this.formSettings.isFooter) {
        return this.formSettings.isFooter
      } else {
        return false
      }
    }
  },
  methods: {
    /**
     * On initialization clone form states
     */
    initFormSettings() {
      // Dynamic form stuff
      if (this.formSettings) {
        // Build and Cache form fields
        this.localSettings = []

        // Create navigation settings group
        if (this.isNavigation && this.formSettings.website) {
          const websiteSettings = {
            name: 'website',
            label: 'Website Options',
            website: this.formSettings.website,
            id: uuid()
          }
          // for (const property in this.formSettings.website) {
          //   if (this.formSettings.website[property]) {
          //     for (const childProp in this.formSettings.website[property]) {
          //       navSettings.navigation.push(property[childProp])
          //     }
          //   } else {
          //     navSettings.navigation.push(property)
          //   }
          // }
          this.localSettings.push(websiteSettings)
        }

        // Create props settings group
        if (this.formSettings.props && this.formSettings.props.length) {
          const props = this.formSettings.props.filter((p) => p.schema.type !== 'repeater')
          if (props.length && props.length !== 0) {
            this.localSettings.push({
              name: 'props',
              label: 'Props',
              props: _.chain(props)
                // clone default object
                // .cloneDeep()
                // sort by children property, so that child props are at the bottom of the list
                .sortBy((o) => o.children)
                // Return value of lodash actions
                .value()
            })
          } else {
            this.localSettings.push({
              name: 'props',
              label: 'Props',
              props: []
            })
          }
        }

        // Create a repeater content group
        // This group will handle repeater settings, and
        // we will flatten this group before saving.
        if (this.formSettings.props && this.formSettings.props.length) {
          const repeaterProps = this.formSettings.props.filter((p) => p.schema.type === 'repeater')

          if (repeaterProps && repeaterProps.length) {
            const sortedContent = repeaterProps.map((p) => {
              const sortedProp = {
                ...p,
                children: _.sortBy(p.children, (c) => c.children)
              }
              return sortedProp
            })

            const repeater_content = {
              name: 'repeater_content',
              label: 'Repeater Content',
              repeater_content: sortedContent
            }

            this.localSettings.push(repeater_content)
          }
        }

        // Create a metadata group
        // This group will handle editing the titles, names, etc. of selected item
        // We will flatten this group before saving.
        // if (!this.formSettings.props) {
        const metadata = {
          name: 'metadata',
          label: 'Metadata',
          metadata: _.pick(this.formSettings, METADATA_FIELDS)
        }
        this.localSettings.push(metadata)
        // }

        // Create style_option settings group
        if (this.formSettings.style_option) {
          const options = this.formSettings.column_widths
            ? _.cloneDeep(_.pick(this.formSettings.style_option, [...STYLE_OPTION_FIELDS, ...ROW_STYLE_OPTION_FIELDS]))
            : _.cloneDeep(_.pick(this.formSettings.style_option, STYLE_OPTION_FIELDS))
          this.localSettings.push({
            name: 'style_option',
            label: 'Style Options',
            style_option: options
          })
        }

        // Cache settings
        this.cachedSettings = _.cloneDeep(this.localSettings)

        // Build and Cache form model
        this.form = this.createFormModel(this.localSettings)
        this.localForm = _.cloneDeep(this.form)
        this.cachedForm = _.cloneDeep(this.localForm)

        // Reset form and cache on next Vue update
        if (this.formSettings && this.$refs.formObs) {
          requestAnimationFrame(() => {
            if (this.$refs.formObs) {
              this.$refs.formObs.reset()
            }
          })
        }
      }
    },
    /**
     * Add selected component to styles and set form settings
     */
    onComponentSelected(component) {
      if (component) {
        const comp = _.cloneDeep(_.omit(component, ['id', 'created_at', 'updated_at', 'version', 'bit_component_name', 'bit_component_url', 'short_name', 'type', 'code']))

        // Add ids and reference ids
        comp.id = uuid()
        // comp.account = { id: this.getSelectedAccountId }
        comp.schema = component // { id: component.id }


        // Build props from prop_schemas
        // and add props to component
        const props = comp.prop_schemas.map((schema) => {
          const prop = {
            id: uuid(),
            // account: { id: this.getSelectedAccountId },
            schema,
            value: schema.default,
            is_schema: false
          }
          delete prop.id

          // Build children props
          if (schema.children && schema.children.length) {
            prop.children = schema.children.map((child) => {
              const childProp = {
                id: uuid(),
                // account: { id: this.getSelectedAccountId },
                schema: child,
                value: child.default,
                is_schema: false
              }

              if (child.children && child.children.length) {
                childProp.children = child.children.map((child) => {
                  const nestedProp = {
                    id: uuid(),
                    // account: { id: this.getSelectedAccountId },
                    schema: child,
                    value: child.default,
                    is_schema: false
                  }
                  return nestedProp
                })
              }

              return childProp
            })
          }

          return prop
        })
        comp.props = props

        // Add default style_option
        comp.style_option = {
          id: uuid(),
          // account: { id: this.getSelectedAccountId },
          class_list: '',
          inline_styles: '',
          wrapping_html: ''
        }

        // Is schema is false for local form settings
        comp.is_schema = false

        // Remove prop schemas
        delete comp.prop_schemas
        this.onSelectFormSettings({ ...comp, ...{ type: 'component' } })

        return comp
      }
    },
    /**
     * Create form model from passed in fields setting array
     */
    createFormModel(fields, mapKey) {
      const form = {}

      if (!fields || (!fields.length && !Object.keys(fields).length)) {
        return form
      }

      // Loop through fields and create object-key model
      if (_.isArray(fields)) {
        for (const field of fields) {
          if (field.props && field.props.length) {
            form.props = this.createFormModel(field.props)
          }

          // Build navigation content
          if (field.website) {
            form.website = this.createFormModel(field.website, 'website')
          }

          // Build repeater content
          if (field.repeater_content) {
            form.repeater_content = this.createFormModel(field.repeater_content, 'repeater_content')
          }

          // Build Style Option model
          if (field.style_option) {
            form.style_option = this.createFormModel(field.style_option, 'style_option')
          }

          // Build Metadata model
          if (field.metadata) {
            form.metadata = this.createFormModel(field.metadata, 'metadata')
          }

          // Build children models
          if (field.children && field.children.length && !field.style_option && !field.metadata) {
            // Handle repeater children form models
            if (field.schema.type === 'repeater') {
              if (field.value) {
                form[field.schema.prop_variable_name] = field.value
              } else {
                form[field.schema.prop_variable_name] = [this.createFormModel(field.children)]
              }
            } else {
              // Handle children form models
              form[field.schema.prop_variable_name] = this.createFormModel(field.children)
            }
          }

          // Build schema children models
          if (field.schema && field.schema.children && field.schema.children.length && !field.children && !field.style_option && !field.metadata) {
            // Handle repeater children form models
            if (field.schema.type === 'repeater') {
              if (field.value) {
                form[field.schema.prop_variable_name] = field.value
              } else {
                form[field.schema.prop_variable_name] = [this.createFormModel(field.schema.children)]
              }
            } else {
              // Handle children form models
              form[field.schema.prop_variable_name] = this.createFormModel(field.schema.children)
            }
          }

          // Build model from schema
          if (!field.style_option && (!field.children || !field.children.length) && field.schema && field.schema.prop_variable_name) {
            if (!field.schema.children || !field.schema.children.length) {
              let value = _.isNil(field.value) ? field.schema.default : field.value

              // This is a typeorm caveat. We cannot have columns of
              // 'any' type, so our value column for props and prop schemas
              // are a 'string' type.
              // For an image we need to use the defaultImage value
              if (field.schema.type === 'image-picker') {
                value = field.schema.defaultImage || {}
              }

              // This is a typeorm caveat. We cannot have columns of
              // 'any' type, so our value column for props and prop schemas
              // are a 'string' type. We need to convert any checkbox or
              // switch input types to a boolean value, so they register
              // the correct default value.
              if (field.schema && (field.schema.type === 'checkbox' || field.schema.type === 'switch')) {
                value = value === 'true' || value === '1' || value === true
              }

              // We need to convert number values too
              if (field.schema && field.schema.type === 'number') {
                value = parseInt(value, 10)
              }

              // Stringify obj that belong in a text input
              // This is usually for config object props (i.e. Google maps config, swiper config, etc.)
              if (field.schema.type === 'text' || field.schema.type === 'textarea') {
                value = typeof value === 'object' && value !== null && value !== undefined ? JSON.stringify(value) : value
              }

              form[field.schema.prop_variable_name] = value
            }
          }

          // Build default model
          if (!field.style_option && !field.schema && field.prop_variable_name) {
            form[field.prop_variable_name] = _.isNil(field.value) ? field.default : field.value
          }
        }
      } else if (_.isPlainObject(fields)) {
        // Handle nested objects
        for (const property in fields) {
          if (_.isPlainObject(fields[property]) && property !== 'logo' && property !== 'image') {
            this.createFormModel(fields[property], mapKey)
          }
        }

        if (mapKey === 'style_option') {
          const options = this.formSettings.column_widths ? [...STYLE_OPTION_FIELDS, ...ROW_STYLE_OPTION_FIELDS] : STYLE_OPTION_FIELDS
          const pickBy = mapKey === 'style_option' ? options : METADATA_FIELDS
          const pickedFields = _.pick(fields, pickBy)
          for (const key in pickedFields) {
            form[key] = fields[key]
          }
        } else {
          for (const key in fields) {
            form[key] = fields[key]
          }
        }
      }
      return form
    },
    /**
     * Update and rebuild form settings with new default values
     * the default form settings values.
     */
    updateFormSettings(model, settings) {
      const formModel = model
      const formSettings = settings
      const fSettings = formSettings

      // Handle content fields by merging back into props
      if (formModel.repeater_content) {
        const contentIndex = formSettings.findIndex((s) => s.repeater_content)
        const repeater_content = formSettings[contentIndex].repeater_content
        // Update form settings
        const propIndex = formSettings.findIndex((s) => s.props)
        repeater_content.forEach((c) => {
          formSettings[propIndex].props.push(c)
        })

        // Remove content from form settings
        formSettings.splice(contentIndex, 1)

        // Update form model
        if (!formModel.props) {
          formModel.props = {}
        }

        for (const key in formModel.repeater_content) {
          formModel.props[key] = formModel.repeater_content[key].map(repeaterValue => {
            return _.mapValues(repeaterValue, (value) => {
              if (typeof value === "string") {
                // remove any preceeding or trailing whitespace
                return _.trim(value)
              }
              else {
                return value
              }
            })
          })
        }

        // Remove content form model
        delete formModel.repeater_content
      }

      if (_.isArray(fSettings)) {
        // Handle default form model object
        for (let i = 0; i < fSettings.length; i++) {
          const fg = fSettings[i]

          // Handle Props
          if (fg.props) {
            delete fg.label
            delete fg.name
            this.updateFormSettings(formModel.props, fg.props)
          }

          // Handle Style Option
          if (fg.style_option) {
            delete fg.label
            delete fg.name
            this.updateFormSettings(formModel.style_option, fg.style_option)
          }

          // Handle Metadata
          if (fg.metadata) {
            delete fg.label
            delete fg.name
            this.updateFormSettings(formModel.metadata, fg.metadata)
          }

          // Handle website options
          if (fg.website) {
            delete fg.label
            delete fg.name
            this.updateFormSettings(formModel.website, fg.website)
          }

          if (!fg.style_option && !fg.metadata && !fg.props && !fg.website) {
            // Handle repeater models
            if (fg.schema.type === 'repeater') {
              fSettings[i].value = formModel[fg.schema.prop_variable_name]
            }
            else if (fg.children && fg.children.length) {
              // Handle children models
              this.updateFormSettings(formModel[fg.schema.prop_variable_name], fg.children)
            }
            else {
              // Handle default models
              if (typeof formModel[fg.schema.prop_variable_name] === "string") {
                // remove any preceeding or trailing whitespace
                fSettings[i].value = _.trim(formModel[fg.schema.prop_variable_name])
              }
              else {
                fSettings[i].value = formModel[fg.schema.prop_variable_name]
              }
            }
          }
        }
      } else if (_.isPlainObject(fSettings)) {
        for (const key in fSettings) {
          if (key === "route") {
            // remove any preceeding or trailing whitespace in routes
            fSettings[key] = _.trim(formModel[key])
          }
          else {
            fSettings[key] = formModel[key]
          }
        }
      }

      return fSettings
    },
    /**
     * Reduce the form settings into an object model to
     * match Vuex Store object model
     */
    reduceFormSettings(formSettings) {
      return formSettings.reduce((result, item, index, array) => {
        const key = Object.keys(item)[0]
        result[key] = item[key]
        return result
      }, {})
    }
  }
}
