
import { mapGetters } from "vuex"
import _ from "lodash"

export default {
  name: 'NavbarBananaPageButtons',
  props: {
    isAlwaysMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropDownLocations: null,
      isTestMode: false,
      testModePhone: '',
      filterStatus: [],
      filterLocations: { id: 'all_locations', name: "All locations" },
      shouldFilterByOnboarding: false,
      dashboards: null,
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
    }),
  }
}
