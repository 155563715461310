
import { mapGetters, mapMutations, mapActions } from "vuex"
import Search from "@/dashboard/search/sandwich-search"
import SearchFuse from "@/dashboard/search/sandwich-search-fuse"
import AlgoliaSearch from "@/dashboard/search/algolia-search"
import ContactStatusMixin from "@/mixins/contact-status"
import _ from "lodash"
import Multiselect from "vue-multiselect"
import NavbarBananaPageHelpLink from "./NavbarBananaPageHelpLink"
import NavbarBananaPageButtons from "./NavbarBananaPageButtons"
import { format } from "date-fns"
import { generateApiUrl } from "@/utils/helpers"
import axios from "axios"
import FeatherIcon from "@/helpers/FeatherIcon"

export default {
  name: 'NavbarBananaPage',
  components: {
    SearchFuse,
    Search,
    AlgoliaSearch,
    Multiselect,
    NavbarBananaPageHelpLink,
    NavbarBananaPageButtons,
    FeatherIcon
  },
  mixins: [ContactStatusMixin],
  props: {
    isContainerFluid: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dropDownLocations: null,
      isTestMode: false,
      testModePhone: '',
      filterStatus: [],
      filterLocations: null,
      previousTimeout: null,
      shouldFilterByOnboarding: false,
      shouldFilterByLive: false,
      dashboards: null,
      userLocationPermissions: null,
      loadCount: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isTestMode = _.clone(this.$store.getters['activityPopup/getIsTestMode'])
      this.testModePhone = _.clone(this.$store.getters['activityPopup/getTestModePhone'])
      this.filterStatus = _.cloneDeep(this.filterStatusVuex)
      this.shouldFilterByOnboarding = _.clone(this.getFilterByOnboarding)
      this.shouldFilterByLive = _.clone(this.getFilterByLive)
    })
  },
  async created() {
    this.userLocationPermissions = await this.userLocationsAndAccess()

    this.loadDashboards()
    this.setLocationFilter()
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      selectedAccount: "accounts/getSelectedAccount",
      parentDashboard: "charts/getParentDashboard",
      locations: "locations/getLocationsWithAddress",
      filterLocationsVuex: "leads/filterLocations",
      filterStatusVuex: "leads/filterStatus",
      isImageUploading: "media/getIsUploading",
      unitCacheStatus: "cache/getUnitCacheStatus",
      selectedAccountId: "user/getSelectedAccountId",
      uploadingError: "media/getIsUploadingError",
      getFilterByOnboarding: "layoutVars/getFilterByOnboarding",
      getFilterByLive: "layoutVars/getFilterByLive",
      uploadingErrorData: "media/getIsUploadingErrorData",
      isAdmin: "charts/getIsAdminCharts",
      allUnitsForLocation: "unitManager/getAllUnitsForLocation",
      user: "user/getUser"
    }),
    updatedAt() {
      return format(new Date(this.layoutVars.edited.updated), "MM/dd/yyyy hh:mm a")
    },
    isAccountsPage() {
      return this.$route.name === 'accounts'
    },
    isDashboardPage() {
      return this.$route.name === 'dashboard-account_id-view-dashboard-dashboard_id'
    },
  },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
      updateSearchItems: "layoutVars/updateSearchItems",
      updateFilterStatus: "leads/updateFilterStatus",
      updateFilterLocations: "leads/updateFilterLocations",
      updateFilterByOnboarding: "layoutVars/updateFilterByOnboarding",
      updateFilterByLive: "layoutVars/updateFilterByLive",
      setIsResetAccount: "layoutVars/setIsResetAccount",
    }),
    ...mapActions({
      loadUnitsForLocations: "unitManager/loadUnitsForLocations",
      userLocationsAndAccess: "user/userLocationsAndAccess",
    }),
    async setLocationFilter() {
      if (this.selectedAccount && this.loadCount < 5) {
        this.loadCount++

        let filter = await this.$vlf.getItem(`filterLocations_${ this.selectedAccount.id }`)
        // if (filter) {
        //   this.filterLocations = filter
        // }
        // else if (this.filterLocationsVuex && Object.keys(this.filterLocationsVuex).length) {
        //   this.filterLocations = _.cloneDeep(this.filterLocationsVuex)
        // }
        if (filter && !this.filterLocationsVuex) {
          this.filterLocations = filter
          this.updateFilterLocations(filter)
        }
        else if (this.filterLocationsVuex && this.filterLocationsVuex.account_id == this.selectedAccount.id) {
          this.updateFilterLocations(this.filterLocationsVuex)
          this.filterLocations = this.filterLocationsVuex
          await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, this.getUnitLocationFilters)
        }
        else {

          if (this.locations && this.locations[0]) {
            this.updateFilterLocations(this.locations[0])
            this.filterLocations = this.locations[0]
            await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, this.locations[0])
          }
          else {
            this.setLocationFilter()
          }
        }
      }
    },
    handleSelected(selected) {


      clearTimeout(this.previousTimeout)
      this.previousTimeout = setTimeout(() => {
        if (selected !== null) {
          this.updateSearchItems(selected)
        } else {
          this.updateSearchItems([])
        }
      }, 200)
    },
    handleSelectedEnter(selected) {
      this.$emit("selected-enter", selected)
    },
    goBack() {
      // this.$router.back((e) => {  }, this.routeAborted)
      let path = this.$route.path.split('/')
      if (path[1] == 'global') {
        this.$router.push(`/${ path[1] }/${ path[2] }`)
      }
      else if (path[1] == 'users') {
        this.$router.push(`/${ path[1] }`)
      }
      else {
        this.$router.push(`/${ path[1] }/${ path[2] }/${ path[3] }`)
      }
    },
    changeDashboard(dash) {
      this.$emit("destroyAll")
      // this.$router.go(`/dashboard/${this.accountId}/dashboard/${dash.id}`)
      // Redirect user to add page
      this.setIsResetAccount(true)
      this.$router.replace(`/dashboard/${ this.selectedAccountId }/view-dashboard/${ dash.id }`, () => {
        // After route navigation completes
        // We use the go router method to refresh the page, so
        // the page reloads and we reset the `hasChanges` method
        this.setIsResetAccount(false)
      })
    },
    async loadDashboards() {
      let where = ""
      if (this.selectedAccount) {
        let url = this.createDashboardApiUrl()

        try {
          // var { data: res } = await
          axios.get(url, { headers: { "x-purge": true } }).then(({ data: res }) => {
            let dashboards = []
            this.dashboards = res.data
          })

        } catch (e) {
          this.$rollbar.error("DateFilter: Error loading dashbaords", e, { account_id: this.accountId, user_id: this.user.id })
        }
      }
    },
    createDashboardApiUrl() {
      const relations = "image"
      var is_super_admin_only = 0
      var where = ""
      var url
      if (this.$isAllowed("superAdmin") && this.isAdmin) {
        is_super_admin_only = 1
        where = `{"is_user_dashboard": 0, "is_visible_to_clients": 0, "is_super_admin_only": 1}`
        url = generateApiUrl(`dashboard?where=${ where }&relations=${ relations }`)
      } else if (this.$isAllowed("superAdmin") && this.isAdmin == false) {
        is_super_admin_only = 1
        where = `{"is_user_dashboard": 0, "is_super_admin_only": 0, "is_unit_manager": ${ this.selectedAccount.use_insights_unit_manager ? 1 : 0 }}`
        url = generateApiUrl(`dashboard?where=${ where }&relations=${ relations }`)
      } else {
        where = `{"is_super_admin_only": 0, "is_user_dashboard": 0, "is_visible_to_clients": 1, "is_unit_manager": ${ this.selectedAccount.use_insights_unit_manager ? 1 : 0 }}`
        url = generateApiUrl(`dashboard?relations=${ relations }&where=${ where }`)
      }

      return url
    },
  },
  watch: {
    isTestMode() {
      this.$store.commit('activityPopup/setIsTestMode', this.isTestMode)
    },
    testModePhone() {
      this.$store.commit('activityPopup/setTestModePhone', this.testModePhone)
    },
    shouldFilterByOnboarding() {
      this.updateFilterByOnboarding(this.shouldFilterByOnboarding)
    },
    shouldFilterByLive() {
      this.updateFilterByLive(this.shouldFilterByLive)
    },
    filterStatus() {
      this.updateFilterStatus(this.filterStatus)
    },
    async filterLocations() {
      if (this.filterLocations && this.filterLocations.id == 'all_locations') {
        // this.updateFilterLocations(null)
        // await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, null)
        if (window && window.Intercom) {
          window.Intercom('update', { "filtered_location_id": null })
        }
      }
      else if (this.filterLocations) {
        this.updateFilterLocations(this.filterLocations)
        await this.$vlf.setItem(`filterLocations_${ this.selectedAccount.id }`, this.filterLocations)
        if (this.filterLocations && window && window.Intercom) {
          window.Intercom('update', { "filtered_location_id": this.filterLocations.id })
        }
      }
      // * set inventory if Unit Manager
      if (this.selectedAccount && this.selectedAccount.use_insights_unit_manager && !this.allUnitsForLocation) {
        try {
          await this.loadUnitsForLocations()
        } catch (e) {

        }
      }
    },
    locations: {
      async handler() {
        if (this.locations) {
          this.dropDownLocations = _.cloneDeep(this.locations)
          if (this.userLocationPermissions && this.userLocationPermissions.hasFullAccess) {
            this.dropDownLocations.unshift({ id: 'all_locations', name: "All locations" })
          }
          else {
            this.userLocationPermissions = await this.userLocationsAndAccess()
            if (this.userLocationPermissions && this.userLocationPermissions.hasFullAccess) {
              this.dropDownLocations.unshift({ id: 'all_locations', name: "All locations" })
            }
          }

          // // * set inventory if Unit Manager
          if (this.selectedAccount && this.selectedAccount.use_insights_unit_manager && !this.allUnitsForLocation) {
            try {
              await this.loadUnitsForLocations()
            } catch (e) {

            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
