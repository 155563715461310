import { render, staticRenderFns } from "./NavbarBananaPageMobile.vue?vue&type=template&id=39d53baa&lang=pug"
import script from "./NavbarBananaPageMobile.vue?vue&type=script&lang=js"
export * from "./NavbarBananaPageMobile.vue?vue&type=script&lang=js"
import style0 from "./NavbarBananaPageMobile.vue?vue&type=style&index=0&id=39d53baa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarBananaPageButtons: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPageButtons.vue').default,AlgoliaSearch: require('/dashboard/search/algolia-search/AlgoliaSearch.vue').default,PugImg: require('/dashboard/pug-img/PugImg.vue').default,NavbarBananaPageHelpLink: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPageHelpLink.vue').default})
