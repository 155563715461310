import * as uuid from 'uuid/v1'

// let uid = uuid()

/**
 * UID MIXIN:
 *
 * We use this mixin to create a unique ID for Aria and component
 * related purposes.
 *
 * As descriped here: https://github.com/vuejs/vue/issues/5886
 */
export default {
  data() {
    return {
      uid: ''
    }
  },
  created() {
    this.uid = uuid()
  }
}
